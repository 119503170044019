import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import passwordStrengthEstimator from "../../utils/passwordStrengthEstimator";

type PasswordStrengthProps = {
    password: string;
};

export default function PasswordStrength({
    password,
}: PasswordStrengthProps): JSX.Element {
    const [passwordScore, setPasswordScore] = useState(0);
    const [scoreLabel, SetScoreLabel] = useState("weak");
    useEffect(() => {
        const calculatePasswordStrength = async () => {
            const { score } = await passwordStrengthEstimator(password);
            setPasswordScore(score);
        };
        calculatePasswordStrength();
    }, [password]);

    useEffect(() => {
        switch (passwordScore) {
            case 2:
                SetScoreLabel("Fair");
                break;
            case 3:
                SetScoreLabel("Good");
                break;
            case 4:
                SetScoreLabel("Strong");
                break;
            default:
                SetScoreLabel("Weak");
        }
    }, [passwordScore]);

    return (
        <Box display="flex" flexDirection="column">
            <LinearProgress
                variant="determinate"
                value={(passwordScore / 4) * 100}
                sx={{
                    flexGrow: 1,
                }}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    padding: "5px 5px 0",
                }}
            >
                <Typography
                    sx={{
                        textAlign: "start",
                        display: "flex",
                        flex: 1,
                    }}
                >
                    Password Strength:
                </Typography>
                <Typography
                    sx={{
                        textAlign: "end",
                    }}
                >
                    {scoreLabel}
                </Typography>
            </Box>
        </Box>
    );
}