import React, { useEffect, useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { auth } from '../../firebase/config';
import {
    RecaptchaVerifier,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider,
    updateProfile
} from "firebase/auth";
import {
    Container, Box, TextField, Button, Typography, Grid, Divider, IconButton, CircularProgress, LinearProgress, Link as MuiLink
} from '@mui/material';
import { useUser } from '../../contexts/UserContext';
import { LoadingButton } from '@mui/lab';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import PasswordStrength from '../../components/passwordStrength/passwordStrength';
import MotionBox from '../../components/motion/motionBox';
import Colors from '../../constants/colors';
import LayoutType from '../../constants/layout-type';

// Function to classify password strength
const getPasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 5) strength += 20;
    if (password.length > 7) strength += 20;
    if (/[A-Z]/.test(password)) strength += 20;
    if (/[0-9]/.test(password)) strength += 20;
    if (/[^A-Za-z0-9]/.test(password)) strength += 20;
    return strength; // returns a value between 0 and 100
};

// Map Firebase error codes to user-friendly messages
const mapFirebaseErrorToMessage = (errorCode) => {
    switch (errorCode) {
        case 'auth/email-already-in-use':
            return 'This email is already in use. Please use a different email.';
        case 'auth/weak-password':
            return 'The password is too weak. Please use a stronger password.';
        default:
            return 'An error occurred during registration. Please try again.';
    }
};

const RegisterPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [wasSubmissionAttempted, setWasSubmissionAttempted] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [generalError, setGeneralError] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [name, setName] = useState('');
    const { user, setUser } = useUser();

    useEffect(() => {
        if (typeof window !== "undefined" && auth) {
            const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                    console.log("reCAPTCHA solved, allow form submission: ", response);
                }
            });
            recaptchaVerifier.render().catch(error => console.error("RecaptchaVerifier render error: ", error));
        }
    }, [auth]);

    useEffect(() => {
        setPasswordStrength(getPasswordStrength(password));
    }, [password]);

    const evaluatePasswordMatch = (inputPassword: String | null, inputConfirmPassword: String | null) => {
        if ((inputPassword ?? password) !== (inputConfirmPassword ?? confirmPassword)) {
            setPasswordError("Passwords do not match.");
            return;
        }

        setPasswordError("");
    };

    const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setWasSubmissionAttempted(true);
        if (password !== confirmPassword) {
            setPasswordError("Passwords do not match.");
            return;
        }

        setLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            if (userCredential.user) {
                await updateProfile(userCredential.user, {
                    displayName: name
                });
            }
            await sendEmailVerification(userCredential.user);
            setUser({
                emailAddress: email,
                emailVerified: false,
                displayName: name
            });
            navigate('/auth/confirm-email');
        } catch (error) {
            const errorCode = error.code;
            setGeneralError(mapFirebaseErrorToMessage(errorCode));
        }
        setLoading(false);
    };

    const handleOAuthSignIn = async (providerId: string) => {
        let provider;
        switch (providerId) {
            case 'google.com':
                provider = new GoogleAuthProvider();
                break;
            case 'apple.com':
                provider = new OAuthProvider('apple.com');
                break;
            case 'microsoft.com':
                provider = new OAuthProvider('microsoft.com');
                break;
            default:
                console.error('Unsupported provider');
                return;
        }

        try {
            await signInWithPopup(auth, provider);
            navigate('/app/dashboard/');
        } catch (error) {
            console.error(`Error signing in with ${providerId}: `, error);
        }
    };

    const firstBoxAnimationProps = useMemo(() => ({
        initial: { opacity: 0, y: -50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 },
        transition: { duration: 1 },
        style: { visibility: 'visible', background: Colors.BACKGROUND_DARK }
    }), []);

    return (<>
        <MotionBox
            {...firstBoxAnimationProps}
        >
            <Container maxWidth="md">
                <Box textAlign="center" pt={13} pb={2} mb={0} flexDirection="column" flex="1" alignItems="center" alignContent="center">
                    <Typography component="h1" variant="h1" sx={{ mt: 4, mb: 1, maxWidth: "100%" }}>
                        Register
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                        Create a Screen Keep account
                    </Typography>
                </Box>
            </Container>
        </MotionBox>
        <Container component="main" maxWidth="sm">
            <Box sx={{ pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <Box component="form" onSubmit={handleRegister} sx={{ mt: 4 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); evaluatePasswordMatch(e.target.value, null); } }
                    />
                    <Box sx={{ maxWidth: '95%', width: '95%', textAlign: 'center', display: 'inline-block' }}>
                        <PasswordStrength password={password} />
                    </Box>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => { setConfirmPassword(e.target.value); evaluatePasswordMatch(null, e.target.value); } }
                        error={!!passwordError}
                        helperText={passwordError}
                    />
                    {generalError && <Box sx={{ textAlign: 'center' }}><Typography color="error" variant="body2" sx={{ mt: 2 }}>{generalError}</Typography></Box>}
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="body2" sx={{ mt: 2, maxWidth: '75%', display: 'inline-block' }}>
                            By creating an account, you agree to our
                            <MuiLink to="/legal/terms-of-use/" component={Link} sx={{ ml: 0.5 }} underline="hover">
                                Terms of Service
                            </MuiLink> and
                            <MuiLink to="/legal/privacy-policy/" component={Link} sx={{ ml: 0.5 }} underline="hover">
                                Privacy Policy
                            </MuiLink>.
                        </Typography>
                    </Box>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={loading}
                        sx={{ mt: 3, mb: 2, maxWidth: '75%' }}
                    >
                        Sign Up
                    </LoadingButton>
                    <Divider sx={{ my: 3 }}>OR</Divider>
                    <Box justifyContent="center">
                        <Button
                            variant="outlined"
                            startIcon={<GoogleIcon />}
                            onClick={() => handleOAuthSignIn('google.com')}
                            fullWidth
                            sx={{ mt: 3, mb: 2, maxWidth: '75%' }}
                        >
                            Continue With Google
                        </Button>
                        {/*<Grid item>*/}
                        {/*    <IconButton onClick={() => handleOAuthSignIn('apple.com')} aria-label="sign up with Apple">*/}
                        {/*        <AppleIcon />*/}
                        {/*    </IconButton>*/}
                        {/*</Grid>*/}
                        {/*<Grid item>*/}
                        {/*    <IconButton onClick={() => handleOAuthSignIn('microsoft.com')} aria-label="sign up with Microsoft">*/}
                        {/*        <MicrosoftIcon />*/}
                        {/*    </IconButton>*/}
                        {/*</Grid>*/}
                    </Box>
                </Box>
                <MuiLink to="/auth/login" color="inherit" component={Link}>
                    <Typography variant="body2" sx={{ mt: 4 }}>
                        {"Already have an account? Sign in"}
                    </Typography>
                </MuiLink>
                <div id="recaptcha-container"></div>
            </Box>
        </Container>
    </>);
};

RegisterPage.layoutType = LayoutType.DefaultFullWidth;

export default RegisterPage;

export const Head: React.FC = () => <title>ScreenKeep - Register</title>;